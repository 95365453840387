import { registerWidget } from '../../../js/core/widget/widget-directory'
import { fetchJsonData } from '../../../js/helpers/json-fetch'
import { getUrlFromString } from '../../../js/document/url'
import { CollectionItemTemplate } from '../../components/collection-row/c-collection-item'
import { CollectionRowTemplate } from '../../components/collection-row/c-collection-row'
import { AbsoluteDialogTemplate } from '../../components/absolute-dialog/c-absolute-dialog.template'
import Component from '../../../js/core/component/component'

const widgetApi = 'w-product-ads'

const widgetQueries = {
  adsContainer: `data-${widgetApi}__container`,
  apiUrl: `data-${widgetApi}__url`,
  adItem: `data-${widgetApi}__item`,
  noDataTitle: `data-${widgetApi}__no-data-title`,
  noDataMessage: `data-${widgetApi}__no-data-message`,
  loader: `data-${widgetApi}__loader`,
  extraParams: `data-${widgetApi}__extra-params`
}

const classQueries = {
  adsContainerWrapper: `${widgetApi}--container`,
  adItem: `${widgetApi}--item`
}

export default class ProductAds {
  /**
   * Creates a new product ads widget.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    if (!element) {
      return
    }
    this.element = element
    this.adsContainer = this.element.querySelector(`[${widgetQueries.adsContainer}]`)
    this.apiUrl = this.element.getAttribute(widgetQueries.apiUrl)
    this.noDataTitle = this.element.getAttribute(widgetQueries.noDataTitle)
    this.noDataMessage = this.element.getAttribute(widgetQueries.noDataMessage)
    this.loader = this.element.querySelector(`[${widgetQueries.loader}]`)
    this.extraParams = this.getExtraParameters()

    this.init()
  }

  /**
   * Initializes the widget.
   */
  init () {
    if (!this.adsContainer) {
      return
    }

    this.fetch()
  }

  /**
   * Returns the extra parameters that are exposed in the DOM
   *
   * @returns {string} - Returns the api url
   */
  getExtraParameters () {
    const extraParamsElements = this.element.querySelectorAll(`[${widgetQueries.extraParams}]`)
    const extraParams = extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          obj[el.name] = el.value
          return obj
        }, {})
      : undefined

    return extraParams
  }

  /**
   * Gets the data from the API
   */
  async fetch () {
    try {
      const fetchUrl = getUrlFromString(this.apiUrl, this.extraParams)
      const fetchedData = await fetchJsonData(fetchUrl).then(result => {
        const data = result && result.data ? result.data[0] : null
        if (data) {
          return {
            ads: data.ads
          }
        }
      })

      this.render(fetchedData)
    } catch (e) {
      if (window.newrelic) {
        window.newrelic.noticeError('[Product Ads] Error with:' + e)
      }
    }
  }

  /**
   * Renders the data
   *
   * @param {Object} data - The data to render.
   */
  render (data) {
    if (this.loader) {
      this.loader.remove()
    }

    if (!data) {
      const messageBox = AbsoluteDialogTemplate({
        title: this.noDataTitle ?? '',
        body: this.noDataMessage ?? '',
        closable: false,
        alertType: 'info'
      })

      this.adsContainer.innerHTML = messageBox

      return
    }

    const ads = data.ads
    if (ads) {
      let adsRendered = ''
      ads.forEach((ad, index) => {
        if (index < 5) {
          adsRendered += CollectionItemTemplate({
            title: ad.contentHeading ?? '',
            subTitle: ad.contentSubheading ? this.buildSubTitle(ad.contentSubheading) : '',
            link: ad.clickUrl ? { href: ad.clickUrl, target: '_blank', title: ad.contentHeading } : null,
            image:
              ad.contentImage && ad.contentImage !== ''
                ? {
                    resolvedSrc: ad.contentImage,
                    title: ad.contentHeading,
                    ratio: '4:3',
                    dynamicRatios: [
                      {
                        ratio: '3:2',
                        bp: 'sml'
                      }
                    ]
                  }
                : {
                    title: ad.contentHeading,
                    ratio: '4:3',
                    dynamicRatios: [
                      {
                        ratio: '3:2',
                        bp: 'sml'
                      }
                    ]
                  },
            attributes: {
              'data-rendered': ad.impressionUrl,
              'data-impression': ad.viewableImpressionUrl,
              [`${widgetQueries.adItem}`]: ''
            },
            extraClasses: `FID__${ad.flightId} ${classQueries.adItem} ${
              ad.contentImage && ad.contentImage !== '' ? '' : 'no-image'
            }`
          })
        }
      })

      const collectionAdsRendered = CollectionRowTemplate({
        html: adsRendered,
        expandedFrom: 'sml',
        extraClasses: classQueries.adsContainerWrapper
      })

      this.adsContainer.innerHTML = collectionAdsRendered
      Component.initDocumentComponentsFromAPI(this.element)

      this.notifyEvents()
      this.observeAds()
    }
  }

  /**
   * Builds the subtitle
   * @param {String} contentSubheading - The content subheading.
   * @returns {String} The subtitle.
   */
  buildSubTitle (contentSubheading) {
    return `<span class="m-icon m-icon--pin ${widgetApi}--item-subtitle-icon"></span>${contentSubheading}`
  }

  /**
   * Notify the events
   */
  notifyEvents () {
    const adItems = this.adsContainer.querySelectorAll(`[${widgetQueries.adItem}]`)
    adItems.forEach(adItem => {
      const rendered = adItem.getAttribute('data-rendered')
      this.notifyAd(rendered)
    })
  }

  /**
   * Observes the ads
   */
  observeAds () {
    const adItems = this.adsContainer.querySelectorAll(`[${widgetQueries.adItem}]`)
    const observedAds = new Map()

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const adItem = entry.target

          if (entry.isIntersecting) {
            const timeoutId = setTimeout(() => {
              const viewed = entry.target.getAttribute('data-impression')
              this.notifyAd(viewed)
              observer.unobserve(entry.target)
            }, 1000)

            observedAds.set(adItem, timeoutId)
          } else {
            if (observedAds.has(adItem)) {
              clearTimeout(observedAds.get(adItem))
              observedAds.delete(adItem)
            }
          }
        })
      },
      { threshold: 0.5 }
    )

    adItems.forEach(adItem => {
      observer.observe(adItem)
    })
  }

  /**
   * Notify the ad
   *
   * @param {String} rendered - The rendered data.
   */
  async notifyAd (rendered) {
    try {
      const fetchUrl = getUrlFromString(rendered)
      const response = await fetch(fetchUrl)

      if (!response.ok) {
        if (window.newrelic) {
          const error = new Error(`[Product Ads] Response notifying status: ${response.status}`)
          window.newrelic.noticeError(error)
        }
      }
    } catch (error) {
      if (window.newrelic) {
        const errorMessage = new Error(`[Product Ads] Error notifyAd with: ${error}`)
        window.newrelic.noticeError(errorMessage)
      }
    }
  }
}

registerWidget(ProductAds, widgetApi)
