import { registerWidget } from '../../../js/core/widget/widget-directory'
import { apiCaller } from '../../../js/helpers/api-caller'
import { getUrlFromString } from '../../../js/document/url'
import { processPackageItem } from '../../../js/data/data-processor'
import { AccoListerContentTemplate } from '../acco-lister/w-acco-lister__content.template'
import { observerAPI, documentObserver } from '../../../js/document/intersector'
import Component from '../../../js/core/component/component'
import Img from '../../components/img/main'
import { getACMPriceLabel } from '../../components/price/c-price.template'
import SessionRecorder from './session-recorder'
import { recommendedAccoListerEvents } from './event-types'
import { bindClickEventToResults } from '../../../js/helpers/event-binder'
import registeredEvents from '../../../js/helpers/registered-events'

const EventEmitter = require('eventemitter3')

const ACM_MODAL_ID_SUFFIX = '__acm-modal'

const widgetApi = 'w-recommended-acco-lister'
const widgetQueries = {
  urlAttr: `data-${widgetApi}__url`,
  urlAcmAttr: `data-${widgetApi}__acm-base-url`,
  priceLabelTextAttr: `data-${widgetApi}__price-label-text`,
  sliderAttr: `data-${widgetApi}-slider`,
  variantAttr: `data-${widgetApi}__variant`,
  showCtaButtonAttr: `data-${widgetApi}__show-cta-button`,
  ctaButtonTextAttr: `data-${widgetApi}__cta-button-text`,
  useSessionRecordingAttr: `data-${widgetApi}__session-recording`,
  extraParams: 'input[type="hidden"]',
  resultsElement: '.w-acco-lister__results',
  trackAttr: 'data-track'
}

/**
 * Recommended Acco lister is ONLY used for the Recommendation spike
 *
 * Some shortcuts have been taken when creating this component, such as (but not limited to):
 * 1- Duplicating the acco-lister: Code duplication. This could become a variant, with time
 * 2- Removed data-layer events
 * 3- When possible, css class from acco-lister have been used (see 1-)
 */
export default class RecommendedAccoLister {
  constructor (element, options = {}) {
    this.element = element
    this.acmModalId = `${widgetApi}-${this.element.id}${ACM_MODAL_ID_SUFFIX}`
    this.resultsElement = this.element.querySelector(widgetQueries.resultsElement)
    this.packageItems = []
    this.events = new EventEmitter()
    this.track = this.element.hasAttribute(widgetQueries.trackAttr) ? this.element.attributes[widgetQueries.trackAttr].value : null

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.element.attributes[widgetQueries.trackAttr].value }
    })

    this.options = {
      ...{
        url: this.element.getAttribute(widgetQueries.urlAttr),
        acmBaseUrl: this.element.getAttribute(widgetQueries.urlAcmAttr),
        priceLabelText: this.element.getAttribute(widgetQueries.priceLabelTextAttr),
        variant: this.element.getAttribute(widgetQueries.variantAttr) || 'default',
        showCtaButton: this.element.getAttribute(widgetQueries.showCtaButtonAttr) || false,
        ctaButtonText: this.element.getAttribute(widgetQueries.ctaButtonTextAttr) || '',
        useSessionRecording: this.element.hasAttribute(widgetQueries.useSessionRecordingAttr) || false,
        urlParams: this.getExtraParameters(),
        originList: 'recommended-acco-lister'
      },
      ...options
    }

    const observer = documentObserver()
    observer.observe(this.element)
    this.element[observerAPI].events.on('enter', () => {
      this.fetchAndUpdate()
        .catch(e => console.warn(e))
      observer.unobserve(this.element)
    })

    this.element[widgetApi] = {
      element: this.element,
      options: this.options
    }

    const [staticContentElement] = document.getElementsByClassName('w-static-data-content')
    this.accoId = staticContentElement.getAttribute('data-w-static-content-data-id')
    if (this.options.useSessionRecording) {
      this.sessionRecorder = new SessionRecorder(this.accoId)
    }
  }

  getAccoListerTipsTemplate (options = []) {
    if (!options.items || !options.items.length > 0) return null
    options.items = options.items.map(item => {
      const modalId = options.acmModalId ? options.acmModalId : this.acmModalId
      return processPackageItem(item,
        {
          acmModalId: modalId,
          acmHideInfoIcon: !!item.price.mandatoryExtraCostsText,
          priceLegend: this.options.priceLabelText,
          priceLabel: item.price.priceDiscounts?.description,
          priceLabel2: item.price.mandatoryExtraCostsText && item.price.acmInformation
            ? getACMPriceLabel({ ...item, text: item.price.mandatoryExtraCostsText, staticText: item.price.staticText, acmUrl: item.price.acmInformation.acmUrl, modalId })
            : item.price.staticText,
          priceLabelGrayed: true,
          acmBaseUrl: this.options.acmBaseUrl,
          originList: options.originList || this.options.originList
        })
    })

    this.packageItems = options.items
    options.showCtaButton = this.options.showCtaButton
    options.ctaButtonText = this.options.ctaButtonText
    const accoListerContent = AccoListerContentTemplate(options)
    return accoListerContent
  }

  getExtraParameters () {
    const extraParamsElements = this.element.querySelectorAll(widgetQueries.extraParams)
    return extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          obj[el.name] = el.value
          return obj
        }, {})
      : undefined
  }

  async fetchAndUpdate () {
    this.element.classList.add('is-loading')

    const parentNode = this.element.parentNode
    if (!parentNode.classList.contains('fr-acco-lister')) return

    const weights = this.sessionRecorder ? this.sessionRecorder.retrievePairs() : {}
    const hideParent = () => {
      parentNode.setAttribute('hidden', 'true')
    }
    const absoluteUrl = getUrlFromString(this.options.url, this.options.urlParams)
    try {
      const response = await apiCaller(absoluteUrl.href, { method: 'post', body: { accoId: this.accoId, weights } })

      if (!response.success) {
        throw new Error('Network response was not ok')
      }
      this.element.classList.remove('is-loading')
      if (response?.response?.results?.length) {
        this.resultsElement.innerHTML = this.getAccoListerTipsTemplate({
          id: this.element.id,
          variant: this.options.variant,
          items: response.response.results,
          track: this.track ? this.track : null
        })
        bindClickEventToResults(this.packageItems, this._getLinksForEachResult(), this.onClickItem, this.events)
        Component.initDocumentComponentsFromAPI(this.element)
        Component.initComponentActionElements(this.element)
        Img.createInstancesOnDocument(this.element)
      } else {
        hideParent()
      }
    } catch (error) {
      hideParent()
    }
  }

  _getLinksForEachResult () {
    const resultElements = Array.from(this.resultsElement.querySelectorAll('.c-package-item'))
    return resultElements.map(result => Array.from(result.querySelectorAll('.c-package-item__link')))
  }

  onClickItem (ev, result, events) {
    events.emit(recommendedAccoListerEvents.RECOMMENDED_ACCO_LISTER_ITEM_CLICKED, { data: { ...result }, event: ev })
  }
}

registerWidget(RecommendedAccoLister, widgetApi)
